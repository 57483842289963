import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './AddFriendRedirect.css';

const AddFriendRedirect = () => {
    const { friendname } = useParams();
    const [showPopup, setShowPopup] = useState(false);
    const [appOpened, setAppOpened] = useState(false);

    useEffect(() => {
        const isAndroid = /android/i.test(navigator.userAgent);
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        const appLink = `dailydrive://link?addFriend?${friendname}`;

        const openApp = () => {
            window.location.href = appLink;
        };

        const fallbackToStore = () => {
            if (!appOpened) {
                setShowPopup(true); // Show the popup
                setTimeout(() => {
                    if (isIOS) {
                        window.location.href = 'https://apps.apple.com/app/id1640130053';
                    } else if (isAndroid) {
                        window.location.href = 'https://play.google.com/store/apps/details?id=com.zack.unity.football.mobile2D';
                    }
                }, 2000); // Delay to allow the popup to be visible before redirect
            }
        };

        // Attempt to open the app after a slight delay
        setTimeout(openApp, 100);

        // Timeout to trigger the fallback if the app isn't opened
        const fallbackTimeout = setTimeout(fallbackToStore, 1500);

        // Detect if the user switches to the app (by listening for 'blur' event)
        const handleAppSwitch = () => {
            setAppOpened(true);
            clearTimeout(fallbackTimeout);
        };

        window.addEventListener('blur', handleAppSwitch);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('blur', handleAppSwitch);
            clearTimeout(fallbackTimeout);
        };
    }, [friendname]);

    return (
        <div>
            {showPopup ? (
                <div className="popup">
                    <p>Added {friendname} as a friend</p>
                </div>
            ) : (
                    <div className="popup">
                        <p>Redirecting to App Store Page</p>
                    </div>
                )}
        </div>
    );
};

export default AddFriendRedirect;