import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AddFriendRedirect from './AddFriendRedirect';

function App() {
  const [isPrivacyPopupOpen, setIsPrivacyPopupOpen] = useState(false);
  const [isTournamentPopupOpen, setIsTournamentPopupOpen] = useState(false);

  const handleEmailClick = () => {
      window.location.href = 'mailto:help@dailydriveapp.com';
  };

  const handlePrivacyPopupOpen = () => {
      setIsPrivacyPopupOpen(true);
  };

  const handleProShopClick = () => {
      window.open('https://dailydriveproshop.company.site', '_blank', 'noopener,noreferrer');
  };

  const handlePrivacyPopupClose = () => {
      setIsPrivacyPopupOpen(false);
  };

  const handleTournamentPopupOpen = () => {
      setIsTournamentPopupOpen(true);
  };

  const handleTournamentPopupClose = () => {
      setIsTournamentPopupOpen(false);
  };

  const appstoreClick = () => {
      // Handle button click action here
      window.open('https://apps.apple.com/us/app/dailydrive/id1640130053', '_blank');
  };

  const googleplayClick = () => {
      // Handle secondary button click action here
      window.open('https://play.google.com/store/apps/details?id=com.zack.unity.football.mobile2D.android&hl=en_US', '_blank');
  };

  return (
      <Router>
          <div className="App">
              <Routes>
                  <Route path="/addfriend/:friendname" element={<AddFriendRedirect />} />
              </Routes>

              <img src={`${process.env.PUBLIC_URL}/bg.png`} alt="Daily Drive Logo" className="bg" />

              <div className="privacy-button" onClick={handlePrivacyPopupOpen}>
                  <img src="privacypolicy.png" alt="Button" />
              </div>

              <div className="googleplay-button" onClick={googleplayClick}>
                  <img src="googleplay.png" alt="Button" />
              </div>
              <div className="appstore-button" onClick={appstoreClick}>
                  <img src="appstore.png" alt="Button" />
              </div>


              <img src={`${process.env.PUBLIC_URL}/dailydrivelogo.png`} alt="Another Image" className="dailydrive-logo" />
              <div className="schedule-wrapper">
                  <button className="button" onClick={handleProShopClick}>
                      Official Pro Shop
            </button>
              </div>
              <div className="help-wrapper">
                  <button className="button" onClick={handleEmailClick}>
                      Contact us
            </button>
              </div>
              {isPrivacyPopupOpen && (
                  <div className="overlay" onClick={handlePrivacyPopupClose}>
                      <div className="popup" onClick={(e) => e.stopPropagation()}>
                          <div className="popup-header"><u><strong>Privacy Policy</strong></u></div>
                          <div className="popup-body">DailyDrive does not collect any personal information from any of your use of our Apps not provided by users.<br /><br /><br />A phone number is required to sign up for the app and is stored in the database, but is not linked to an identity or used for any besides account setup and functionality.<br /><br /><br />If we have any of your personal information we will not willingly sell or give it to anyone, except if lawfully subpoenaed to produce it and then only after offering you a reasonable chance to challenge such subpoena if allowed by law.</div>
                      </div>
                  </div>
              )}
              {isTournamentPopupOpen && (
                  <div className="overlay" onClick={handleTournamentPopupClose}>
                      <div className="popup" onClick={(e) => e.stopPropagation()}>
                          <div className="popup-header"><u><strong>Schedule Tournament</strong></u></div>
                          <div className="popup-body"><strong>What we can offer you:</strong>-18 days of in app advertising through your brands own virtual golf tournament.<br />-Exposure to a user pool of over 500 real golfers<br />-Display a select product catalog to our users with a link to sales.<br />-Social media brand engagement and support.<br /><br /><br /><strong>Please include in email:</strong>-Name of your golf brand.<br />-Prize you can provide for the single tournament winner.<br />-Dates that work best for you (For 18 day advertising period).</div>
                          <button className="popup-button" onClick={handleEmailClick}>Schedule now</button>
                      </div>
                  </div>
              )}
          </div>
      </Router>
  );
}

export default App;
